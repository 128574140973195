const Config = {
  api: process.env.REACT_APP_QGLUE_API || 'http://localhost:9090',
  host: process.env.REACT_APP_URL || 'http://localhost:3000',
  auth: {
    domain: 'qglue.auth0.com',
    clientID: 'wDmg-ov7lUHKjfC_uwf15Dg6ZrXaMG-a',
    audience: 'https://qglue.herokuapp.com',
  }
}

export default Config;