import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
    },
  },
  logo: {
    marginBottom: theme.spacing.unit * 4,
  },
  paper: {
    padding: 100,
    textAlign: 'center',
  },
  message: {
    marginTop: theme.spacing.unit * 4,
    color: theme.palette.error.main,
  },
  detail: {
    marginTop: theme.spacing.unit * 4,
  }
});

class ErrorPage extends React.Component {
  render() {
    let { message, classes } = this.props;
    return (
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <img className={classes.logo} src="/img/logo640.png" height="30" alt="QGlue Logo" />
            <Typography variant="h5">We are having problems!</Typography>
            <Typography variant="h5" className={classes.message}>
              {message || "We not really sure what is going on! Try refreshing the page or come back later"}
            </Typography>
            <Typography variant="body1" className={classes.detail}>Try to refresh the page and if that doesn't work, come back later.</Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ErrorPage);
