import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';
import { Button } from '@material-ui/core';

import ConnectButton from './ConnectButton';

class Clio extends Component {
  handleCustom = () => {
    this.props.enableCustom();
  }

  render() {
    return (
      <div>
        <ConnectButton serviceId="clio" serviceLabel="CLIO" />
        <Button onClick={this.handleCustom}>
          Enable Custom
        </Button>
      </div>
    );
  }
}


function mapDispatchToProps(d) {
  return bindActionCreators(ActionCreators, d);
}

export default connect(null, mapDispatchToProps)(Clio);
