import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { Typography, CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Welcome, Account, Settings, Trust, TrustClient, Clients, AppNotification } from "..";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    // paddingTop: 0
  },
});

const Blank = () => (
  <Typography variant="body1" style={{ padding: 200 }}>Not implemented</Typography>
);

class Page extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        
        <main className={classes.content}>
          <Switch>
            <Route path="/welcome" component={Welcome} />
            <Route path="/settings" component={Settings} />
            <Route path="/trust/client/:id" component={TrustClient} />
            <Route path="/trust" component={Trust} />
            <Route path="/clients" component={Clients} />
            <Route path="/account" component={Account} />
            <Route path="/sample" component={Blank} />
            <Route path="/yay" component={Blank} />

            <Redirect to="/welcome" />
          </Switch>
        </main>
        <AppNotification />
      </div>
    )
  }
}


export default withStyles(styles)(Page);