import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { ActionCreators } from '../../store/actions';

class Subscription extends Component { 
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.marginTop} spacing={24}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
              subscription
          </Paper>
        </Grid>
      </Grid>
    );
  }
}


const styles = theme => ({
  marginTop: {
    marginTop: 10
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
  }
}, mapDispatchToProps)(withStyles(styles)(Subscription));
