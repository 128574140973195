import * as types from '../types';
import auth0 from 'auth0-js';
import jwt_decode from 'jwt-decode';
import * as app from './app';

import config from '../../config';
import api from '../../lib/api';

const _auth0 = new auth0.WebAuth({
  ...config.auth,
  redirectUri: `${config.host}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email read:config'
});


function authenticated(dispatch, getState, authInfo) {
  // debugger;
  console.log(authInfo);
  if (!authInfo.idToken) {
    return dispatch({
      type: types.AUTH_LOGIN_NONE,
    });
  }

  // Save state in local storage
  localStorage.setItem('authInfo', JSON.stringify({
    accessToken: authInfo.accessToken,
    idToken: authInfo.idToken,
    expires: authInfo.expires,
  }));

  const profile = jwt_decode(authInfo.idToken);
  const expired = new Date().getTime() > authInfo.expires;
  if (expired) {
    // Refresh session if expired
    localStorage.removeItem('authInfo');
    return _auth0.authorize({ prompt: 'none' });
    
    // return _auth0.checkSession({}, (error, authResult) => {
    // //  debugger;
    //   if (authResult && authResult.accessToken && authResult.idToken) {
    //     authResult.expires = (authResult.expiresIn * 1000) + new Date().getTime();
    //     authenticated(dispatch, getState, authResult);
    //   } else {
    //     dispatch({
    //       type: types.AUTH_LOGIN_FAILURE,
    //       error
    //     });
    //   }
    // });
  }

  // We are logged in, so initiate app
  api.setToken(authInfo.accessToken);
  dispatch({
    type: types.AUTH_LOGIN_SUCCESS,
    authInfo,
    profile
  });
  dispatch(app.load());
}


export function authenticate() {
  return (dispatch, getState) => {
    // TODO I think this gets called by mistake
    // debugger;
    const authInfoString = localStorage.getItem('authInfo');
    authenticated(dispatch, getState, authInfoString ? JSON.parse(authInfoString) : {});
  }
}

export function login() {
  return (dispatch, getState) => {
    _auth0.authorize();
  }
}

export function logout() {
  return (dispatch, getState) => {
    localStorage.removeItem('authInfo');
    window.location.href = `https://${config.auth.domain}/v2/logout?client_id=${config.auth.clientID}&returnTo=${config.host}`;
  }
}

export function callback() {
  return (dispatch, getState) => {
    dispatch({
      type: types.AUTH_LOGIN_REQUEST,
    });
    _auth0.parseHash((error, result) => {
      if (error || !result) {
        return dispatch({
          type: types.AUTH_LOGIN_FAILURE,
          error,
        });
      }
      result.expires = (result.expiresIn * 1000) + new Date().getTime();
      authenticated(dispatch, getState, result);
    });
  }
}
