import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { CreateAccount } from '..';
import ErrorPage from './ErrorPage';

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});

const ProtectedRoute = ({ component: Component, app, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        {app.fatal && <ErrorPage message={app.notification.message}/>}
        {!app.fatal && (
          <div>
            {(auth.loading || app.loading) && <CircularProgress />}
            {(!auth.loading && !auth.loggedIn) && <Redirect to="/login" />}
            {(auth.loggedIn && !app.loading && !app.user) && <CreateAccount />}
            {(auth.loggedIn && !app.loading && app.user) && <Component {...props} />}
          </div>
        )}
      </div>
    )}
  />
);

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
