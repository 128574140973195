import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { ActionCreators } from '../../store/actions';

import ContentWrapper from '../Shared/ContentWrapper';

class Welcome extends Component { 
  render() {
    const { classes, } = this.props;

    return (
      <ContentWrapper>
        <Grid container className={classes.marginTop} spacing={24}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Typography variant="h4">Welcome to QGlue!</Typography>
                <Typography variant="body1">
                  Get ready for the best sync between law firms and QuickBooks Online.
                </Typography>
            </Paper>
          </Grid>
        </Grid>
      </ContentWrapper>
    );
  }
}


const styles = theme => ({
  marginTop: {
    marginTop: 10
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
  }
}, mapDispatchToProps)(withStyles(styles)(Welcome));
