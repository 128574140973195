import * as types from '../types';
import api from '../../lib/api'
import * as clients from './clients';

const convert = (t, getState) => {
  t.date = new Date(t.date);
  if (!t.client && t.qbo && !t.qbo.client.id) {
    // For transactions assigned to matters, we don't have a client id
    // for QBO clients, so we are adding it here
    const { qbo } = getState().clients;
    const client = qbo.find(c => c.matters.find(m => m.id === t.qbo.matter.id));
    t.qbo.client.id = client.id;
  }
  return t;
}

export function setTransactionFilter(filter) {
  return (dispatch, getState) => {
    dispatch({
      type: types.TRUST_TRANSACTIONS_FILTER,
      filter
    });
  }
}

export function listTrustClients() {
  return (dispatch, getState) => {
    dispatch({
      type: types.TRUST_CLIENTS_REQUEST,
    });
    return api.get(`/v1/trust/clients`).then(result => {
      const { trustClients } = result.data;
      dispatch({
        type: types.TRUST_CLIENTS_SUCCESS,
        trustClients,
      })
      return clients;
    });
  }
}


export function syncClioTrustClient(clioClient) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRUST_CLIO_REQUEST,
      clients,
    })
    return api.post(`/v1/clio/clients/${clioClient.id}/sync`)
      .then(cres => {
        const { client } = cres.data;
        return api.post(`/v1/trust/clients`, { clientId: client.id }).then(tres => {
          const trust = tres.data.trust;
          dispatch({
            type: types.TRUST_CLIO_SUCCESS,
            trust,
          })
          return clients;
        });
      });
  }
}

export function syncClioTrustTransaction(clioTransaction) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRUST_CLIO_TRANSACTION_REQUEST,
      clients,
    })
    return api.post(`/v1/clio/transactions/sync`, clioTransaction)
      .then(result => {
        const transaction = convert(result.data.transaction, getState);
        dispatch({
          type: types.TRUST_CLIO_TRANSACTION_SUCCESS,
          transaction,
        });
        return transaction;
      });
  }
}

export function syncQboTrustTransaction(qboTransaction) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRUST_QBO_TRANSACTION_REQUEST,
      clients,
    })
    return api.post(`/v1/qbo/transactions/sync`, qboTransaction)
      .then(result => {
        const transaction = convert(result.data.transaction, getState);
        dispatch({
          type: types.TRUST_QBO_TRANSACTION_SUCCESS,
          transaction,
        });
        return transaction;
      });
  }
}


export function connectTransactions(transaction, src, dest) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRUST_CONNECT_REQUEST,
      clients,
    })
    return api.post(`/v1/trust/transactions/connect`, {
      date: transaction.date,
      amount: transaction.amount,
      description: transaction.description,
      src,
      dest,
    }).then(result => {
        const transaction = convert(result.data.transaction, getState);
        dispatch({
          type: types.TRUST_CONNECT_SUCCESS,
          transaction,
        });
        return Promise.all([listTrustClients(), listTrustTransactions()]).then(() => {
          return transaction;
        });
      });
  }
}


export function listTrustTransactions() {
  return (dispatch, getState) => {
    dispatch({
      type: types.TRUST_TRANSACTIONS_REQUEST,
    });

    const config = getState().app.user.config.trusts;

    const clio = api.get(`/v1/clio/accounts/${config.clioAccount}/transactions`)
      .then(result => result.data.transactions);

    const qbo = api.get(`/v1/qbo/accounts/${config.qboBank}/transactions`)
      .then(result => result.data.transactions);

    const connected = api.get('/v1/trust/transactions')
      .then(result => result.data.transactions);

    return Promise.all([clio, qbo, connected]).then(results => {
      const transactions = results.flat().map(t => convert(t, getState));
      dispatch({
        type: types.TRUST_TRANSACTIONS_SUCCESS,
        transactions,
      })
      return transactions;
    });
  }
}


export function orphanedClients(accounts, trust) {
  const subaccounts = accounts.map(a => a);
  trust.clients.forEach(c => {
    const found = subaccounts.find(a => a.id === c.account.id);
    found.client = c;
  });

  let orphanedClients = [];
  if (!trust.loading) {
    // Creates list of clients from the transactions so that we can spot any that
    // need to be mapped to a liability account
    const clientMap = trust.transactions
      .filter(t => t.clio && t.client)
      .reduce((map, t) => {
        if (!map[t.client.id]) {
          map[t.client.id] = {
            client: t.client,
            balance: 0
          };
        }
        map[t.client.id].balance += t.amount;
        return map;
      }, {});

    const clioClients = Object.keys(clientMap).map(id => clientMap[id]);
    clioClients.forEach(c => {
      let account = subaccounts.find(a => a.client && a.client.clio.id === c.client.id);
      if (account) {
        account.client = c;
      } else {
        orphanedClients.push(c);
      }
    });
  }
}