import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import { ActionCreators } from '../../store/actions';

import { ContentWrapper } from "..";
import { IconButton, Typography } from '@material-ui/core';
import Transactions from './Transactions';

const styles = theme => ({
  marginTop: {
    marginTop: 10
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});


class TrustClient extends Component {
  constructor(props) {
    super(props);


    const { match, trust, setTransactionFilter } = this.props;
    const clientId = Number(match.params.id);
    setTransactionFilter({
      ...trust.transactionFilter,
      client: clientId,
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.push(value);
  };

  render() {
    const { classes, push } = this.props;

    const headerContent = (
      <Fragment>
        <IconButton onClick={() => push('/trust')}>
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Client Transactions</Typography>
      </Fragment>
    );

    return (
      <ContentWrapper header={headerContent}>
        <div className={classes.root}>
          <Transactions />
        </div>
      </ContentWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(state => {
  return {
    trust: state.trust
  }
}, mapDispatchToProps)(withStyles(styles)(TrustClient));




