import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import TrustIcon from '@material-ui/icons/AccountBalance';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ClientsIcon from '@material-ui/icons/People';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/core/styles';
import { ActionCreators } from '../../store/actions';
import Progress from './Progress';


const styles = {

};

const Menu = ({ classes, push, logout, config, connected, path }) => {
  const trustEnabled = config.trusts && config.trusts.clioAccount && config.trusts.qboBank && config.trusts.qboLiability;
  const clientsEnabled = connected.qbo && connected.clio;
  return (
    <div>
      <List>
        <ListItem button disabled={!clientsEnabled} onClick={() => push('/clients')} selected={path.indexOf('/clients') === 0}>
          <ListItemIcon><ClientsIcon /></ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>
        <ListItem button disabled={!trustEnabled} onClick={() => push('/trust')} selected={path.indexOf('/trust') === 0}>
          <ListItemIcon><TrustIcon /></ListItemIcon>
          <ListItemText primary="Trust" />
        </ListItem>
        <ListItem button onClick={() => push('/settings')} selected={path.indexOf('/settings') === 0}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={() => push('/account')} selected={path.indexOf('/account') === 0}>
          <ListItemIcon><AccountIcon /></ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>

        <Divider />
        <Progress />
        <Divider />

        <ListItem button onClick={() => logout()}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
    </div>
  );
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    path: state.router.location.pathname,
    config: state.app.user.config,
    connected: state.app.connected,
  }
}, mapDispatchToProps)(withStyles(styles)(Menu));
