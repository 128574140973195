import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Button } from '@material-ui/core';
import RightIcon from '@material-ui/icons/ArrowForward';
import LeftIcon from '@material-ui/icons/ArrowBack';
import LinkIcon from '@material-ui/icons/Launch';
import ConnectIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import ConnectedIcon from '@material-ui/icons/DragHandle';
import { ActionCreators } from '../../store/actions';
import * as format from "../../lib/formatters";

import TransactionFilter from "./TransactionFilter";

const styles = {
}

const Empty = () => <Typography variant="subtitle1">No transactions</Typography>;


class Trust extends Component {
  constructor(props) {
    super(props);

    // Need clients first because transactions require clients
    // to in some cases map them to qbo clients
    props.listClients().then(() => {
      props.listAccounts();
      props.listTrustTransactions();
    });
  }

  clioSync = transaction => () => {
    this.props.syncClioTrustTransaction(transaction);
  }

  qboSync = transaction => () => {
    this.props.syncQboTrustTransaction(transaction);
  }

  openClio = transaction => () => {
    const id = this.props.config.clioAccount;
    const link = `https://app.clio.com/nc/#/bank_accounts/${id}`;
    window.open(link);
  }

  openQbo = transaction => () => {
    const type = transaction.amount < 0 ? 'check' : 'deposit';
    const link = `https://app.sandbox.qbo.intuit.com/app/${type}?txnId=${transaction.qbo.id}`;
    window.open(link);
  }

  handleFilterChange = filter => {
    this.props.setTransactionFilter(filter);
  }
  
  connect = a => {
    const b = a.connect.with;
    this.props.connectTransactions(a, a.clio ? a.clio : b.clio, a.qbo ? a.qbo : b.qbo);
  }

  render() {
    const { classes, trust } = this.props;
    if (trust.transactions.loading) {
      return <CircularProgress />
    }
    const transactions = trust.filteredTransactions;
    // Mark transactions that can be connected
    for (var i = 0; i < transactions.length - 1; i++) {
      const a = transactions[i];
      const b = transactions[i + 1];
      if (a.date.getTime() === b.date.getTime() && a.amount === b.amount && ((!a.qbo && b.qbo) || (a.qbo && !b.qbo))) {
        a.connect = { top: true, with: b };
        b.connect = { bottom: true, with: a };
        i++;
      }
    }
    const filter = {
      ...trust.transactionFilter,
      client: null
    };

    return (
      <div>
        <TransactionFilter filter={filter} onChange={this.handleFilterChange} />

        {!transactions.length && <Empty />}
        {transactions.length > 0 && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Matter</TableCell>
                <TableCell>Clio - QuickBooks</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((line, i) => (
                <TableRow key={i}>
                  <TableCell>{format.shortDate(line.date)}</TableCell>
                  <TableCell>{line.description}</TableCell>
                  <TableCell align="right">{format.amount(line.amount)}</TableCell>
                  <TableCell>
                    {line.client && line.client.name}
                  </TableCell>
                  <TableCell>
                    {line.matter && line.matter.name}
                  </TableCell>
                  <TableCell>
                    {!line.connect && line.clio && !line.qbo && (
                      <Button onClick={this.clioSync(line)}>
                        <RightIcon />
                      </Button>
                    )}
                    {!line.connect && line.qbo && !line.clio && (
                      <Button onClick={this.qboSync(line)}>
                        <LeftIcon />
                      </Button>
                    )}
                    {line.qbo && line.clio && (
                      <Button disabled>
                        <ConnectedIcon />
                      </Button>
                    )}
                    {line.connect && line.connect.top && (
                      <Button onClick={() => this.connect(line, line.connect.with)}>
                        <div style={{ transform: 'rotate(-90deg)' }}>
                          <ConnectIcon />
                        </div>
                      </Button>
                    )}
                    {line.connect && line.connect.bottom && (
                      <Button onClick={() => this.connect(line, line.connect.with)}>
                        <div style={{ transform: 'rotate(90deg) scaleY(-1)' }}>
                          <ConnectIcon />
                        </div>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {line.qbo && (
                      <Button onClick={this.openQbo(line)}>
                        <LinkIcon />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    trust: state.trust,
    config: state.app.user.config.trusts,
  }
}, mapDispatchToProps)(withStyles(styles)(Trust));
