import createReducer from '../../lib/createReducer';
import * as types from '../types';

const initialState = {
  loading: true,
  loggedIn: false,
  authInfo: null,
  profile: null,
}

export default createReducer(initialState, {
  [types.AUTH_LOGIN_REQUEST](state, action) {
    return {
      loading: true,
      loggedIn: false,
      authInfo: null,
    };
  },

  [types.AUTH_LOGIN_SUCCESS](state, action) {
    return {
      loading: false,
      loggedIn: true,
      authInfo: action.authInfo,
      profile: action.profile,
    };
  },

  [types.AUTH_LOGIN_FAILURE](state, action) {
    return {
      loading: false,
      loggedIn: false,
      error: action.error,
    };
  },

  [types.AUTH_LOGIN_NONE](state, action) {
    return {
      loading: false,
      loggedIn: false,
    };
  },

  [types.AUTH_LOGOUT](state, action) {
    return {
      loading: false,
      loggedIn: false,
    };
  },

  [types.AUTH_SIGNED_UP](state, action) {
    return {
      loading: false,
      loggedIn: true,
      authInfo: action.authInfo,
    };
  },
});
