import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { ActionCreators } from '../../store/actions';

import ContentWrapper from "./ContentWrapper";

const styles = theme => ({
  marginTop: {
    marginTop: 10
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  tabs: {
    // marginBottom: theme.spacing.unit * 2,
  }
});

const TopTab = tabs => {

  class TabsContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: (tabs.find(t => t.path === props.router.location.pathname) || tabs[0]).path,
      }
    }

    handleChange = (event, value) => {
      this.setState({ value });
      this.props.push(value);
    };

    render() {
      const { classes, app } = this.props;
      const { value } = this.state;

      function disabled(t) {
        return t.disabled === true || (t.disabled instanceof Function && Boolean(t.disabled(app)));
      }

      const headerContent = (
        <Tabs
          className={classes.tabs}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          centered
        >
          {tabs.map(t => <Tab key={t.path} value={t.path} disabled={disabled(t)} label={t.label} />)}
        </Tabs>
      );

      return (
        <ContentWrapper header={headerContent}>
          <div className={classes.root}>
            <Switch>
              {tabs.map((tab, i) => (
                <Route key={i} path={tab.path} component={() => tab.component} />
              ))}
              <Route component={() => tabs[0].component} />
            </Switch>
          </div>
        </ContentWrapper>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
  }

  return connect((state) => {
    return {
      router: state.router,
      app: state.app,
    }
  }, mapDispatchToProps)(withStyles(styles)(TabsContainer));
}


export default TopTab;

