import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

import { Button } from '@material-ui/core';
import api from '../../lib/api';
import config from '../../config';

class ConnectButton extends Component {
  static defaultProps = {
    classes: {}
  }

  connect() {
    const { serviceId } = this.props;
    api.get(`/oauth/${serviceId}/url?redirect=${config.host}/settings/${serviceId}`).then(result => {
      window.location.href = result.data.url;
    });
  }

  render() {
    const { serviceId, serviceLabel } = this.props;
    return (
      <div>
        {this.props.app.connected[serviceId]
          ? <Button variant="outlined" disabled={true}>
            {serviceLabel} Connected
                </Button>
          : <Button variant="outlined" color="primary" onClick={this.connect.bind(this)}>
            Connect {serviceLabel}
          </Button>}
      </div>
    );
  }
}


ConnectButton.propTypes = {
  classes: PropTypes.object,
  serviceId: PropTypes.string.isRequired,
  serviceLabel: PropTypes.string.isRequired,
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
  }
}, mapDispatchToProps)(ConnectButton);
