import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Stepper, StepButton, Step } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ActionCreators } from '../../store/actions';


const styles = {
  root: {
    flexGrow: 1
  }
};

const steps = [{
  label: "Connect QuickBooks",
  path: "/settings/qbo",
  completed: (app) => app.connected && app.connected.qbo,
}, {
  label: "Connect Clio",
  path: "/settings/clio",
  completed: (app) => app.connected && app.connected.clio,
}, {
  label: "Configure Trust",
  path: "/settings/trust",
  completed: (app) => app.user.config.trusts 
    && app.user.config.trusts.clioAccount
    && app.user.config.trusts.qboBank
    && app.user.config.trusts.qboLiability
}, {
  label: "Try Sample",
  path: "/sample",
  optional: true,
  completed: false,
}, {
  label: "Celebrate!",
  path: "/yay",
}];


class Progress extends React.Component {
  state = {
    currentStep: 0,
  };

  handleStep = index => () => {
    const { path } = steps[index];
    if (path) {
      this.props.push(path);
    }
    this.setState({ currentStep: index });
  }

  render() {
    const { classes, app } = this.props;
    const { currentStep } = this.state;

    return (
      <div className={classes.root}>
        <Stepper orientation="vertical" nonLinear activeStep={currentStep}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepButton onClick={this.handleStep(index)}
              completed={Boolean(step.completed && step.completed(app))}>
                {step.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
  }
}, mapDispatchToProps)(withStyles(styles)(Progress));
