import createReducer from '../../lib/createReducer';
import * as types from '../types';

const initialState = {
  transactions: [],
  trustClients: [],
  filteredTransactions: [],
  transactionFilter: {
    dateRange: {
      start: null,
      end: null,
    },
    qbo: true,
    clio: true,
    connected: true,
    notConnected: true,
    deposit: true,
    check: true,
    client: null,
  },  
}


function byDateThenAmount(a, b) {
  if (a.date > b.date) return 1;
  if (a.date < b.date) return -1;
  if (a.amount < b.amount) return 1;
  if (a.amount > b.amount) return -1;
}


function filteredTransactions(transactions, filter) {
  if (transactions.loading) {
    return [];
  }
  const range = filter.dateRange;
  const filtered = transactions
    .filter(t => (filter.qbo && t.qbo) || (!filter.qbo && !t.qbo) || (filter.clio && t.clio) || (!filter.clio && !t.clio))
    .filter(t => (filter.connected && t.client) || (!filter.connected && !t.client) || (filter.notConnected && !t.client) || (!filter.notConnected && t.client))
    .filter(t => (filter.deposit && t.amount >= 0) || (!filter.deposit && t.amount < 0) || (filter.check && t.amount < 0) || (!filter.check && t.amount >= 0))
    .filter(t => (!range.start || (range.start && t.date >= new Date(range.start))) && (!range.end || (range.end && t.date <= new Date(range.end))))
    .filter(t => (!filter.client || (t.client && filter.client === t.client.id)))
    .sort(byDateThenAmount);
  return filtered;
}

function combinedSyncedTransactions(transactions) {
  const synced = transactions.filter(t => t.client && t.client.id);
  const orphans = findOrphans(synced, 'clio')
    .concat(findOrphans(synced, 'qbo'))
  return synced.concat(orphans).sort((a, b) => a.date > b.date)

  function findOrphans(synced, type) {
    return transactions
      .filter(t => !(t.client && t.client.id) && t[type] && !synced.find(s => s[type].id === t[type].id))
      .map(t => ({
        ...t,
        client: t[type].client ? { name: t[type].client.name } : undefined,
        matter: t[type].matter ? { name: t[type].matter.name } : undefined,
      }));
  }
}

export default createReducer(initialState, {
  [types.TRUST_TRANSACTIONS_REQUEST](state) {
    return {
      ...state,
      transactions: { loading: true },
    };
  },

  [types.TRUST_TRANSACTIONS_SUCCESS](state, { transactions }) {
    const combined = combinedSyncedTransactions(transactions);
    return {
      ...state,
      transactions: combined,
      filteredTransactions: filteredTransactions(combined, state.transactionFilter)
    }
  },

  [types.TRUST_CONNECT_SUCCESS](state, { transaction }) {
    // filter out transactions that we connected
    const transactions = state.transactions
      .filter(t => (!t.clio || (t.clio.id !== transaction.clio.id))
        && (!t.qbo || (t.qbo.id !== transaction.qbo.id)))
      .concat(transaction);
    const { trustClient } = transactions;
    const trustClients = state.trustClients
      .filter(tc => tc.id !== trustClient.id)
      .concat(trustClient);
    return {
      ...state,
      trustClients,
      transactions,
      filteredTransactions: filteredTransactions(transactions, state.transactionFilter)
    };
  },

  [types.TRUST_TRANSACTIONS_FILTER](state, { filter }) {
    return {
      ...state,
      transactionFilter: Object.assign({}, filter),
      filteredTransactions: filteredTransactions(state.transactions, filter)
    }
  },

  [types.TRUST_CLIO_TRANSACTION_SUCCESS](state, { transaction }) {
    const transactions = state.transactions.concat(transaction);
    const combined = combinedSyncedTransactions(transactions);
    return {
      ...state,
      transactions: combined,
      filteredTransactions: filteredTransactions(combined, state.transactionFilter)
    }
  },


  [types.TRUST_QBO_TRANSACTION_SUCCESS](state, { transaction }) {
    const transactions = state.transactions.concat(transaction);
    const combined = combinedSyncedTransactions(transactions);
    return {
      ...state,
      transactions: combined,
      filteredTransactions: filteredTransactions(combined, state.transactionFilter)
    }
  },

  [types.TRUST_CLIENTS_REQUEST](state) {
    return {
      ...state,
      trustClients: { loading: true },
    };
  },

  [types.TRUST_CLIENTS_SUCCESS](state, { trustClients }) {
    return {
      ...state,
      trustClients,
    };
  },
});
