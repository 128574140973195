import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, lightBlue } from '@material-ui/core/colors'

import { Main } from './components';

const store = configureStore(/* initial state */);

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: lightBlue[200]
    },
    primary: {
      main: green[800]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});


class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
              <Main />
          </ConnectedRouter>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
