import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Typography } from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ArrowBack';
import ConnectedIcon from '@material-ui/icons/DragHandle';
import { ActionCreators } from '../../store/actions';
import * as format from "../../lib/formatters";

const styles = theme => ({
});

const Empty = () => <Typography variant="subtitle1">No information</Typography>;

class Accounts extends Component {
  constructor(props) {
    super(props);

    props.listClients();
    props.listAccounts();
    props.listTrustClients();
    props.listTrustTransactions();
  }

  handleSync = client => () => {
    this.setState({ syncing: true });
    this.props.syncClioTrustClient(client).then(() => {
      this.setState({ syncing: false });
    })
  }

  handleAccountSync = account => () => {
    console.log('SYNC', account);
  }

  render() {
    const { classes, config, trustClients, accounts, push } = this.props;
    if (accounts.loading) {
      return <CircularProgress />;
    }
    if (!accounts.clio.banks || !accounts.qbo.banks) {
      return <Empty />;
    }

    const clioBank = accounts.clio.banks.find(a => config.clioAccount === a.id);
    const qboBank = accounts.qbo.banks.find(a => config.qboBank === a.id);
    const qboLiability = accounts.qbo.liabilities.find(a => config.qboLiability === a.id);

    let subaccounts = [];
    if (!trustClients.loading) {
      subaccounts = qboLiability.accounts.map(account => {
        const tc = trustClients.find(tc => account.id === tc.account.id);
        return {
          ...tc,
          account,
        }
      });
    }

    return (
      <Table className={classes.table}>
        {/* Bank accounts */}
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Clio Bank Account</TableCell>
            <TableCell />
            <TableCell colSpan={2}>QuickBooks Bank Account</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{clioBank.name}</TableCell>
            <TableCell align="right">{format.amount(clioBank.balance)}</TableCell>
            <TableCell />
            <TableCell>{qboBank.name}</TableCell>
            <TableCell align="right">{format.amount(qboBank.balance)}</TableCell>
          </TableRow>
        </TableBody>

        {/* Liability account */}
        <TableHead>
          <TableRow />
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell />
            <TableCell colSpan={2}>QuickBooks Liability Account</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>{qboLiability.name}</TableCell>
            <TableCell align="right">{format.amount(qboLiability.balance)}</TableCell>
          </TableRow>
        </TableBody>

        {/* Client subaccounts */}
        <TableHead>
          <TableRow />
          <TableRow>
            <TableCell colSpan={2}>Clio Clients</TableCell>
            <TableCell />
            <TableCell colSpan={2}>QuickBooks Sub-Accounts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!trustClients.loading && subaccounts.map((sa, i) => (
            <TableRow key={i}>
              <TableCell>
                {sa.client && sa.client.name}
              </TableCell>
              <TableCell />
              <TableCell>
                <Button
                  disabled={!sa.id}
                  onClick={() => push(`/trust/client/${sa.id}`)}
                >
                  {sa.id && <ConnectedIcon />}
                  {!sa.id && <LeftIcon />}
                </Button>
              </TableCell>
              <TableCell>{sa.account.name}</TableCell>
              <TableCell align="right">{format.amount(sa.account.balance)}</TableCell>
            </TableRow>
          ))}
          {/* {orphanedClients.map(c => (
            <TableRow key={c.client.id}>
              <TableCell>{c.client.name}</TableCell>
              <TableCell align="right">{format.amount(c.balance)}</TableCell>
              <TableCell>
                <Button onClick={this.handleSync(c.client)}>
                  <RightIcon />
                </Button>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    trustClients: state.trust.trustClients,
    config: state.app.user.config.trusts,
    accounts: state.accounts,
  }
}, mapDispatchToProps)(withStyles(styles)(Accounts));
