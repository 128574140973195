import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import { ActionCreators } from '../../store/actions';

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    const { profile } = this.props.auth;
    this.props.createAccount({
      email: profile.email,
      firstName: profile.given_name,
      lastName: profile.family_name,
      name: 'My Firm',
    }).then(() => {
      this.props.push("/welcome");
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} spacing={24} justify="center">
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Creating account...</Typography>
            <CircularProgress />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 40,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
    auth: state.auth,
  }
}, mapDispatchToProps)(withStyles(styles)(CreateAccount));
