import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Drawer, Divider, IconButton, AppBar, Toolbar, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


import Menu from '../Page/Menu';

const drawerWidth = 240;

const styles = theme => ({
  contentWrapper: {
    marginTop: 64, // Height of AppBar
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#fff"
  },
  menuButton: {
    marginRight: 20,
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  toolbar: theme.mixins.toolbar,
  sideToolbar: {
    ...theme.mixins.toolbar,
    padding: 0,
    paddingLeft: theme.spacing.unit * 2,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    marginLeft: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
});

class ContentWrapper extends Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState((oldState) => ({
      mobileOpen: !oldState.mobileOpen
    }));
  };
  
  render() {
    const { children, header, classes } = this.props;
    const { mobileOpen } = this.state;
    const { handleDrawerToggle } = this;

    const DrawerContent = () => (
      <div>
        <div className={classes.toolbar}>
          <img className={classes.logo} src="/img/logo640.png"
          height="30" alt="QGlue Logo" />
        </div>
        <Divider />
        <Menu />
      </div>
    );

    const nav = (
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <DrawerContent/>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerContent/>
          </Drawer>
        </Hidden>
      </nav>
    );

    return (
      <Fragment>
        {nav}
        <AppBar position="fixed" className={classes.appBar} color="default">
          <Toolbar>
            <Hidden mdUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            
            {header}

          </Toolbar>
        </AppBar>

        <div className={classes.contentWrapper}>
          {children}
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ContentWrapper);;