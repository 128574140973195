import createReducer from '../../lib/createReducer';
import * as types from '../types';

const initialState = {
  loading: false,
  qbo: {},
  clio: {},
}

export default createReducer(initialState, {
  [types.ACCOUNTS_REQUEST](state, action) {
    return {
      ...state,
      loading: true,
      qbo: {},
      clio: {},
    };
  },

  [types.ACCOUNTS_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      qbo: action.qbo || state.qbo,
      clio: action.clio || state.clio,
    };
  },
});
