import React from 'react';
import TopTab from '../Shared/TopTab';

import Transactions from './Transactions';
import Accounts from './Accounts';

const tabs = [{
  path: '/trust/accounts',
  label: 'Accounts',
  component: <Accounts />
}, {
  path: '/trust/transactions',
  label: 'Transactions',
  component: <Transactions />
}];

export default TopTab(tabs);