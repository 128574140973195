import React from 'react';

import ConnectButton from './ConnectButton'
import Clio from './Clio'
import ClientOptions from './ClientOptions'
import TrustOptions from './TrustOptions'
import TopTab from '../Shared/TopTab';


const tabs = [{
  path: '/settings/qbo',
  label: 'QuickBooks',
  component: <ConnectButton serviceId="qbo" serviceLabel="QuickBooks" />
}, {
  path: '/settings/clio',
  label: 'Clio',
  component: <Clio />,
  }, {
  path: '/settings/trust',
  label: 'Trust',
  component: <TrustOptions />,
  disabled: app => !(app.connected.qbo && app.connected.clio)
}, {
  path: '/settings/clients',
  label: 'Clients',
  component: <ClientOptions />,
  disabled: app => !(app.connected.qbo && app.connected.clio)
}];

export default TopTab(tabs);
