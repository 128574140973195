import createReducer from '../../lib/createReducer';
import * as types from '../types';

const initialState = {
  loading: false,
  qbo: [],
  clio: [],
  clients: [],
}

export default createReducer(initialState, {
  [types.CLIENTS_REQUEST](state, action) {
    return {
      ...state,
      loading: true,
    };
  },

  [types.CLIENTS_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      qbo: action.qbo || state.qbo,
      clio: action.clio || state.clio,
      clients: action.clients || state.clients,
    };
  },

  [types.CLIENT_SYNC_SUCCESS](state, { client }) {
    const qbo = state.clients.find(c => c.qbo && c.qbo.id === client.qbo.id);
    const clio = state.clients.find(c => c.clio && c.clio.id === client.clio.id);
    if (qbo === clio) {
      // we already have a record of this so no change in state
      return state;
    }    
    const clients = state.clients.filter(c => c !== qbo && c !== clio);
    clients.push({
      ...clio,
      qbo: { id: qbo.id }
    });
    return {
      ...state,
      clients,
    };
  },
});
