import * as types from '../types';
import api from '../../lib/api';
import * as app from './app';

export function listClients() {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLIENTS_REQUEST,
    });

    return Promise.all([
      api.get('/v1/clio/clients'),
      api.get('/v1/qbo/clients'),
      api.get('/v1/clients')
    ]).then(([clioResult, qboResult, clientResult]) => {
      const clio = clioResult.data.clients;
      const qbo = qboResult.data.clients;
      const synced = clientResult.data.clients;

      const clients = clio.map(client => {
        client.clio = { id: client.id };
        const sync = synced.find(s => s.clio.id === client.id);
        if (sync) {
          client.synced = new Date(sync.synced);
          client.qbo = { id: sync.qbo.id };
        }
        return client;
      })
      qbo.forEach(client => {
        if (!synced.find(s => s.qbo.id === client.id)) {
          clients.push({
            ...client,
            qbo: { id: client.id }
          });
        }
      })
      
      dispatch({
        type: types.CLIENTS_SUCCESS,
        clients,
        qbo,
        clio,
      });
    });

  }
}

export function syncClioClient(client) {
  // syncing clio client to qbo
  return syncRequest('clio', client.id);
}

export function syncQboClient(client) {
  // syncing qbo client to clio
  return syncRequest('qbo', client.id);
}

function syncRequest(type, id) {
  return (dispatch) => {
    dispatch({
      type: types.CLIENT_SYNC_REQUEST,
    });
    return api.post(`/v1/${type}/clients/${id}/sync`)
      .then(result => {
        dispatch({
          type: types.CLIENT_SYNC_SUCCESS,
          client: result.data.client,
        });
      })
      .catch(err => {
        dispatch(app.error(err));
      });
  }
}
