import React, { Component, Fragment } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Button, Table, TableHead, TableCell, TableRow, TableBody, CircularProgress, Typography } from '@material-ui/core';

import SyncIcon from '@material-ui/icons/CompareArrows';

import { ActionCreators } from '../../store/actions';

import ClientRow from './ClientRow';
import ContentWrapper from '../Shared/ContentWrapper';
import SearchBox from "../Shared/SearchBox";

const styles = theme => ({
  grow: {
    flexGrow: 1,
  }
});

class Clients extends Component {
  constructor(props) {
    super(props);
    props.listClients();

    // Note - not needed because we use an arrow function
    // this.handleSearch = this.handleSearch.bind(this);
  }

  state = {
    search: "",
  };

  handleSearch = event => {
    this.setState({
      search: event.target.value
    });
  };

  EmptyState = props => {
    return;
  };

  render() {
    const { classes, clients } = this.props;
    const { search } = this.state;

    if (clients.loading) {
      return <CircularProgress />;
    }
    const lines = clients.clients.sort((a, b) =>
      a.name.localeCompare(b.name)
    ).filter(a => {
      if (!search.length) return true;
      return a.name.includes(search);
    });

    var emptyState;
    if (!lines.length && search.length) {
      emptyState = <Typography variant="subtitle1">No results</Typography>;
    } else if (!lines.length) {
      emptyState = <Typography variant="subtitle1">No clients</Typography>;
    }

    // if (!lines.length && search.length) {
    //   return <Typography variant="subtitle1">No results</Typography>
    // } else if (!lines.length) {
    //   return <Typography variant="subtitle1">No clients</Typography>
    // }

    const headerContent = (
      <Fragment>
        <Typography variant="h6" color="inherit" noWrap>
          Clients
        </Typography>
        
        <div className={classes.grow} />
        <SearchBox onChange={this.handleSearch}/>
      </Fragment>
    );

    return (
      <div>
        <ContentWrapper header={headerContent}>
          <Fragment>
            {lines.length > 0 &&
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Clio</TableCell>
                    <TableCell>
                      <Button><SyncIcon /></Button>
                    </TableCell>
                    <TableCell>QuickBooks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lines.map((client, i) => <ClientRow key={i} client={client} />)}
                </TableBody>
              </Table>
            }
            {emptyState}
          </Fragment>
        </ContentWrapper>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    clients: state.clients,
  }
}, mapDispatchToProps)(withStyles(styles)(Clients));
