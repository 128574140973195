import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

import { Page, AppNotification } from '..'

import ProtectedRoute from './ProtectedRoute';
import Login from './Login'
import Callback from './Callback'
import ClioCallback from './ClioCallback'


class Main extends Component {
  constructor(props) {
    super(props);
    this.props.authenticate();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <Switch>
            <Route path="/callback" component={Callback} />
            <Route path="/login" component={Login} />
            <Route path="/clio" component={ClioCallback} />

            <ProtectedRoute path="/" component={Page} classes={classes} />
          </Switch>
        </div>
        <AppNotification />
      </div>
    );
  }
}


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    auth: state.auth,
  }
}, mapDispatchToProps)(withStyles(styles)(Main));


