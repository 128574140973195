
export const amount = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
}).format;

export const shortDate = (new Intl.DateTimeFormat('en-US', {
  month: 'numeric',
  day: 'numeric'
})).format;

export const longDate = new Intl.DateTimeFormat('en-US', {
  month: 'numeric',
  day: 'numeric',
  year: '2-digit'
}).format;