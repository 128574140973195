import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';
import app from './app'
import auth from './auth'
import trust from './trust'
import accounts from './accounts';
import clients from './clients';

export default history => 
  combineReducers({
    router: connectRouter(history),
    app,  
    auth, 
    trust, 
    accounts,
    clients,
  });

