import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { ActionCreators } from '../../store/actions';

const styles = theme => ({
  info: {
    marginBottom: theme.spacing.unit * 2
  },
  select: {
    marginBottom: theme.spacing.unit * 4,
  }
});


class TrustOptions extends Component {
  constructor(props) {
    super(props);
    props.listAccounts();
  }

  handleChecked = name => event => {
    let config = this.props.app.user.config.trusts || {};
    config[name] = event.target.checked;
    this.props.updateConfig({ trusts: config });
  }

  handleSelect = name => event => {
    let config = this.props.app.user.config.trusts || {};
    config[name] = event.target.value || null;
    if (!config[name]) {
      config.sync = false;
    }
    this.props.updateConfig({ trusts: config });
  }

  handleSyncChange = value => () => {
    let config = this.props.app.user.config.trusts || {};
    config.sync = value;
    this.props.updateConfig({ trusts: config });
  }

  render() {
    const { accounts, app, classes } = this.props;
    const config = app.user.config.trusts || {};

    const AccountSelection = ({ label, prop, options }) => (
      <Grid container>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth className={classes.select}>
            <InputLabel htmlFor={prop}>{label}</InputLabel>
            <Select
              disabled={Boolean(config.sync)}
              value={config[prop] || ''}
              onChange={this.handleSelect(prop)}
            >
              <MenuItem value=""><i>None</i></MenuItem>
              {options && options.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );


    return (
      <div>
        {accounts.loading && <CircularProgress />}
        {!accounts.loading &&
          <div>
            <Typography variant="body1" className={classes.info}>
              QGlue syncs a trust account from Clio with bank and liability accounts in QuickBooks Online. Point us to the accounts to sync. The liability account is used to keep track of client balances through subaccounts.
            </Typography>
            <AccountSelection label="Clio Account" prop="clioAccount" options={accounts.clio.banks} />
            <AccountSelection label="QuickBooks Bank Account" prop="qboBank" options={accounts.qbo.banks} />
            <AccountSelection label="QuickBooks Liability Account" prop="qboLiability" options={accounts.qbo.liabilities} />

            <FormControlLabel
              control={<Checkbox disabled checked />}
              label="Create liability subaccounts for clients"
            />
            <div>
              {/* <FormControlLabel
              control={<Checkbox
                disabled={!Boolean(config.qboBank && config.qboLiability && config.clioAccount)}
                checked={Boolean(config.sync)}
                onChange={this.handleChecked('sync')}
              />}
              label="Enable Trust Account Syncing"
            /> */}
              {!config.sync && (
                <Button
                  disabled={!Boolean(config.qboBank && config.qboLiability && config.clioAccount)}
                  onClick={this.handleSyncChange(true)}
                  variant="outlined" color="primary"
                >
                  Enable Trust Account Syncing
              </Button>
              )}
              {config.sync && (
                <Button
                  onClick={this.handleSyncChange(false)}
                  variant="outlined" color="secondary"
                >
                  Disable
              </Button>
              )}
            </div>
          </div>
        }
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
    accounts: state.accounts,
  }
}, mapDispatchToProps)(withStyles(styles)(TrustOptions));