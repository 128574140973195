import React from 'react';
import { Typography } from '@material-ui/core'
import TopTab from '../Shared/TopTab';

const Blank = () => (
  <Typography variant="body1" style={{ padding: 200 }}>Not implemented</Typography>
);

const tabs = [{
  path: '/account/subscription',
  label: 'Subscription',
  component: <Blank />
}, {
  path: '/account/profile',
  label: 'Profile',
  component: <Blank />,
  }, {
  path: '/account/users',
  label: 'Users',
  component: <Blank />,
  disabled: true
}];

export default TopTab(tabs);
