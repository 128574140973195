
import config from '../config.js';
import axios from 'axios';


class ApiManager {
  setToken(token) {
    this.token = token;
  }

  get(url) {
    return axios.get(`${config.api}${url}`, this.headers());
  }

  delete(url) {
    return axios.delete(`${config.api}${url}`, this.headers());
  }
  
  post(url, body) {
    return axios.post(`${config.api}${url}`, body, this.headers());
  }

  put(url, body) {
    return axios.put(`${config.api}${url}`, body, this.headers());
  }
  
  headers() {
    return { 
      headers: { Authorization: `Bearer ${this.token}`}
    }
  }
}

const api = new ApiManager();
export default api;
