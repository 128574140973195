import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

class Callback extends Component {
  componentWillMount() {
    this.props.callback();
  }

  render() {
    return (<Redirect to="/" />);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    auth: state.auth,
  }
}, mapDispatchToProps)(Callback);
