import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

// http://localhost:3000/clio?
//custom_action_id=158494&
//user_id=347745724&
//subject_url=%2Fapi%2Fv4%2Fmatters%2F1164413299
//&custom_action_nonce=65696df940555632bb7d8751faf8aa6a

class ClioCallback extends Component {
  // componentWillMount() {
    
  // }

  render() {
    return <p>Clio callback</p>
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    auth: state.auth,
  }
}, mapDispatchToProps)(ClioCallback);
