import createReducer from '../../lib/createReducer';
import * as types from '../types';

const initialState = {
  loading: true,
  fatal: false,
  notification: {},
  user: null,
  connected: {},
}

export default createReducer(initialState, {
  [types.ACCOUNT_LOADED](state, action) {
    return {
      ...state,
      loading: false,
      user: action.user || state.user,
      connected: action.connected || state.connected,
    };
  },

  [types.ACCOUNT_NONE](state) {
    return {
      ...state,
      loading: false,
      user: null,
      connected: {},
    };
  },

  [types.APP_NOTIFICATION_MESSAGE](state, action) {
    return {
      ...state,
      notification: action.notification,
      fatal: action.notification.type === 'fatal',
    };
  },

  [types.APP_NOTIFICATION_CLEAR](state) {
    return {
      ...state,
      notification: {},
      fatal: false,
    };
  },
});
