import * as AppActions from './app'
import * as AuthActions from './auth'
import * as TrustActions from './trust'
import * as AccountActions from './accounts'
import * as ClientActions from './clients'
import { push } from 'connected-react-router';


export const ActionCreators = Object.assign({},
  { push },
  AppActions,
  AuthActions,
  TrustActions,
  AccountActions,
  ClientActions,
);
