// account
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_NONE = 'AUTH_LOGIN_NONE';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SIGNED_UP = 'AUTH_SIGNED_UP';

export const APP_NOTIFICATION_MESSAGE = 'APP_NOTIFICATION_MESSAGE';
export const APP_NOTIFICATION_CLEAR = 'APP_NOTIFICATION_CLEAR';

export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_NONE = 'ACCOUNT_NONE';

export const SET_CLIO_WEBHOOKS = 'SET_CLIO_WEBHOOKS';
export const SET_QBO_INFO = 'SET_QBO_INFO';

export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENT_SYNC_REQUEST = 'CLIENT_SYNC_REQUEST';
export const CLIENT_SYNC_SUCCESS = 'CLIENT_SYNC_SUCCESS';

export const ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST';
export const ACCOUNTS_SUCCESS = 'ACCOUNTS_SUCCESS';
export const QBO_ACCOUNTS_REQUEST = 'QBO_ACCOUNTS_REQUEST';
export const QBO_ACCOUNTS_SUCCESS = 'QBO_ACCOUNTS_SUCCESS';
export const CLIO_ACCOUNTS_REQUEST = 'CLIO_ACCOUNTS_REQUEST';
export const CLIO_ACCOUNTS_SUCCESS = 'CLIO_ACCOUNTS_SUCCESS';

export const TRUST_TRANSACTIONS_REQUEST = 'TRUST_TRANSACTIONS_REQUEST';
export const TRUST_TRANSACTIONS_SUCCESS = 'TRUST_TRANSACTIONS_SUCCESS';
export const TRUST_TRANSACTIONS_FILTER = 'TRUST_TRANSACTIONS_FILTER';
export const TRUST_CLIENTS_REQUEST = 'TRUST_CLIENTS_REQUEST';
export const TRUST_CLIENTS_SUCCESS = 'TRUST_CLIENTS_SUCCESS';
export const TRUST_CLIO_REQUEST = 'TRUST_CLIO_REQUEST';
export const TRUST_CLIO_SUCCESS = 'TRUST_CLIO_SUCCESS';
export const TRUST_CLIO_TRANSACTION_REQUEST = 'TRUST_CLIO_TRANSACTION_REQUEST';
export const TRUST_CLIO_TRANSACTION_SUCCESS = 'TRUST_CLIO_TRANSACTION_SUCCESS';
export const TRUST_QBO_TRANSACTION_REQUEST = 'TRUST_QBO_TRANSACTION_REQUEST';
export const TRUST_QBO_TRANSACTION_SUCCESS = 'TRUST_QBO_TRANSACTION_SUCCESS';
export const TRUST_CONNECT_REQUEST = 'TRUST_CONNECT_REQUEST';
export const TRUST_CONNECT_SUCCESS = 'TRUST_CONNECT_SUCCESS';
