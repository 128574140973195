import * as types from '../types';
import api from '../../lib/api'

export function error(message) {
  return notify(message, 'error');
}

export function notify(message, type) {
  return (dispatch, getState) => {
    dispatch({
      type: types.APP_NOTIFICATION_MESSAGE,
      notification: {
        message,
        type
      },
    });
  }
}

export function notificationClear() {
  return (dispatch, getState) => {
    dispatch({
      type: types.APP_NOTIFICATION_CLEAR,
    });
  }
}

export function load() {
  return (dispatch, getState) => {
    return api.get('/v1/me/').then(result => {
      if (!result.data.id) {
        return dispatch({
          type: types.ACCOUNT_NONE,
        });
      }
      dispatch({
        type: types.ACCOUNT_LOADED,
        user: result.data,
        connected: result.data.connected || {},
      });
    }).catch(err => {
      notify("Access to QGlue API failed: " + (err.message || err), 'fatal')(dispatch, getState);
    });
  }
}

export function createAccount(options) {
  return (dispatch, getState) => {
    return api.post('/v1/accounts', options).then(result => {
      dispatch({
        type: types.ACCOUNT_LOADED,
        user: result.data,
      });
    })
  };
}

export function updateConfig(config) {
  return (dispatch, getState) => {
    return api.put('/v1/me', { config }).then(result => {
      dispatch({
        type: types.ACCOUNT_LOADED,
        user: result.data,
      });
    });
  }
}


export function enableClientSync() {
  return (dispatch, getState) => {
    return api.post('/v1/sync/clients').then(result => {
      dispatch({
        type: types.ACCOUNT_LOADED,
        user: result.data,
      });
    });
  }
}

export function disableClientSync(type) {
  return async (dispatch, getState) => {
    let result = await api.delete('/v1/sync/clients');
    dispatch({
      type: types.ACCOUNT_LOADED,
      user: result.data,
    });
  }
}


export function enableCustom() {
  return (dispatch, getState) => {
    return api.post('/v1/clio/custom');
  }
}