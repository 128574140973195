import * as types from '../types';
import api from '../../lib/api';

export function listAccounts() {
  return (dispatch, getState) => {
    dispatch({
      type: types.ACCOUNTS_REQUEST,
    });

    return Promise.all([
      api.get('/v1/clio/accounts'), 
      api.get('/v1/qbo/accounts')
    ]).then(([clio, qbo]) => {
      dispatch({
        type: types.ACCOUNTS_SUCCESS,
        qbo: qbo.data.accounts,
        clio: clio.data.accounts,
      });
    });
  }
}
