import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, TableCell, TableRow, CircularProgress } from '@material-ui/core';
import ConnectedIcon from '@material-ui/icons/DragHandle';
import RightIcon from '@material-ui/icons/ArrowForward';
import LeftIcon from '@material-ui/icons/ArrowBack';
import { ActionCreators } from '../../store/actions';

const styles = {
  table: {
  }
}

class ClientRow extends Component {
  state = {
    syncing: false
  }

  handleSync = () => {
    const { client } = this.props;
    if (client.clio && !client.qbo) {
      this.setState({ syncing: true });
      this.props.syncClioClient(client).then(() => {
        this.setState({ syncing: false });
      })
    }
    if (client.qbo && !client.clio) {
      this.setState({ syncing: true });
      this.props.syncQboClient(client).then(() => {
        this.setState({ syncing: false });
      })
    }
  }

  render() {
    const { client } = this.props;
    const { syncing } = this.state;

    return (
      <TableRow>
        <TableCell>{client.clio && client.name}</TableCell>
        <TableCell>
          {syncing && (
            <Button disabled>
              <CircularProgress size={20} />
            </Button>
          )}
          {!syncing && (
            <Button onClick={this.handleSync}>
              {client.clio ? (client.qbo ? <ConnectedIcon /> : <RightIcon />) : <LeftIcon />}
            </Button>
          )}
        </TableCell>
        <TableCell>{client.qbo && client.name}</TableCell>
      </TableRow>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    clients: state.clients,
  }
}, mapDispatchToProps)(withStyles(styles)(ClientRow));
