import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

const styles = theme => ({
  toggle: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
    background: theme.palette.background.default,
  },
});

class TransactionFilter extends Component {

  handleTypeChange = value => {
    this.props.onChange({
      ...this.props.filter,
      check: value !== 'deposit',
      deposit: value !== 'check',
    })
  }

  handleSrcDestChange = value => {
    this.props.onChange({
      ...this.props.filter,
      qbo: value !== 'clio',
      clio: value !== 'qbo',
    })
  }

  handleRangeChange = prop => event => {
    const value = event.target.value;
    this.props.onChange({
      ...this.props.filter,
      dateRange: {
        ...this.props.filter.dateRange,
        [prop]: value,
      }
    })
  }

  render() {
    const { filter, classes } = this.props;
    const typeValue = filter.check && filter.deposit ? 'both' : (filter.check ? 'check' : 'deposit');
    const srcDestValue = filter.qbo && filter.clio ? 'both' : (filter.qbo ? 'qbo' : 'clio');

    return (
      <Grid container className={classes.filters}>
        <Grid item xs={12} sm={4}>
          <div className={classes.toggle}>
            <ToggleButtonGroup value={srcDestValue} exclusive onChange={(e, v) => this.handleSrcDestChange(v)}>
              <ToggleButton value="both">
                Both
              </ToggleButton>
              <ToggleButton value="clio">
                Clio
              </ToggleButton>
              <ToggleButton value="qbo">
                QuickBooks
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.toggle}>
            <ToggleButtonGroup value={typeValue} exclusive onChange={(e, v) => this.handleTypeChange(v)}>
              <ToggleButton value="both">
                All
              </ToggleButton>
              <ToggleButton value="deposit">
                Deposits
              </ToggleButton>
              <ToggleButton value="check">
                Checks
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue={filter.dateRange.start || ''}
            className={classes.textField}
            onChange={this.handleRangeChange('start')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            defaultValue={filter.dateRange.end || ''}
            className={classes.textField}
            onChange={this.handleRangeChange('end')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(TransactionFilter);