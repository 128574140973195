import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

import MySnackBar from './MySnackBar';

class AppNotification extends React.Component {
  handleClose = () => {
    this.props.notificationClear();
  }

  render() {
    const { notification } = this.props;
    const message = notification.message ? (notification.message.message || notification.message) : '';
    const open = notification.message && notification.type !== 'fatal';
    return (
      <MySnackBar
        message={message}
        variant={notification.type || 'info'}
        open={Boolean(open)}
        onClose={this.handleClose}
      />
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    notification: state.app.notification,
  }
}, mapDispatchToProps)(AppNotification);
