import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ActionCreators } from '../../store/actions';

import { Checkbox, FormControlLabel } from '@material-ui/core';


class ClientOptions extends Component {
  handleChange = event => {
    let enabled = event.target.checked;
    if (enabled) {
      this.props.enableClientSync();
    } else {
      this.props.disableClientSync();
    }
  }

  render() {
    return (
      <div>
        {this.props.app.user && <FormControlLabel
          control={<Checkbox
            disabled={!this.props.app.connected.clio}
            checked={!!this.props.app.user.config.clients}
            onChange={this.handleChange.bind(this)}
          />}
          label="Clients" />
        }
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect((state) => {
  return {
    app: state.app,
  }
}, mapDispatchToProps)(ClientOptions);